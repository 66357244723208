<template>
  <v-dialog id="dialog" v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on }">
      <v-btn small text v-on="on" class="secondary--text mt-2">
        <v-icon small class="mr-1">mdi-plus</v-icon>
        Add Server
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span>
          Add a new server
        </span>
      </v-card-title>
      <v-card-text>
        <v-form ref="urlForm" v-model="urlValid" v-on:submit.prevent="save()">
          <v-text-field required label="Server URL" hint="http://my-ai-server.example.com/api/predict"
                :rules="urlRules" v-model="server.url" />  
        </v-form>
        <p class="caption">
          You will be able to set concurrency, authentication and other settings once
          the server is added.
        </p>
      </v-card-text>

      <v-card-actions>
          <v-btn text small outlined v-on:click.native="resetForm()" class="secondary--text">Cancel</v-btn>
          <v-spacer> </v-spacer>
          <v-btn small v-on:click.native="save()" text class="primary white--text">
            Add
          </v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>
    
<script>

export default {
  data() {
    return {
      step: 1,
      dialog: false,

      server: {
        url: '',
        // TODO: authentication, concurrency, etc.
        concurrency: 4
      },

      nameValid: false,
      
      urlValid: false,
      urlRules: [
        value => !!value || 'Required.',
        value => (value || '').length <= 200 || 'Max 200 characters',
        // value => (value || '').length >= 3 || 'Min 3 characters', 'http://'
        value => {
          return this.isURL(value) || `Invalid URL.`
        },
      ],
    }
  },

  props: {
    model: {
      type: Object,
      required: true
    }
  },

  computed: {
    loading() {
      return this.$store.state.model.loading
    },
    error() {
      return this.$store.state.model.error
    },    
  },

  methods: {
    async save() {
      if (!this.urlValid) {
        return
      }      

      let payload = Object.assign({}, this.model)
      let servers = this.model.servers.slice(0)
      
      this.server.url = this.server.url.trim()
      
      servers.push(this.server)

      payload.servers = servers
    
      await this.$store.dispatch('SetModel', payload)
      if (this.error === null) {
        let q = {
          projectId: this.model.projectId,
          modelId: this.model.id
        }
        this.$store.dispatch('GetModel', q)
        this.resetForm()
      }
    },

    resetForm() {
      // closing dialog
      this.dialog = false
      
      this.server = {
        url: ''
      }
    },

    isURL(str) {
      if (str.length > 200 || str.length < 1) {
        return false
      }
      try {
        new URL(str);
        return true;
      } catch (err) {
        return false;
      }
    }
  }
}

</script>